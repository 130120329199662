<template>
  <div>
    <div class="container" align="left">
      <div class="row d-flex mt-5">
        <h3 class="mb-4"><strong>개인정보취급(처리)방침입니다.</strong></h3>
        <p align="left">
          에스케이핀크스 주식회사(이하 “SK핀크스”)는 회원의 개인정보를 매우 중요하게 생각하며 회원이 제공하여 준 모든
          정보가 철저히 보호 받을 수 있도록 최선을 다하고 있습니다. 이에 “SK핀크스”는 『개인정보보호법』, 『정보통신망
          이용 촉진 및 정보보호 등의 관련 법률』 등 정보통신서비스 제공자가 준수하여야 할 관련 법규상의 개인정보보호
          규정 및 정보통신부가 제정한 개인정보보호 지침을 준수하고 있습니다.
        </p>
        <p style="margin: 0">“SK핀크스”의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.</p>
        <p class="numText2">
          <small
            >※ “개인정보의 처리”란 개인정보의 수집, 생성, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구,
            이용, 제공, 공개, 파기(破棄), 그 밖에 이와 유사한 행위를 말합니다.
          </small>
        </p>

        <div class="bookmark-links">
          <a href="#section-01">1. 개인정보의 수집 범위 및 방법</a>
          <a href="#section-02">2. 개인정보의 수집 및 이용목적</a>
          <a href="#section-03">3. 개인정보의 처리 및 취급 보유 기간</a>
          <a href="#section-04">4. 개인정보의 파기절차 및 방법</a>
          <a href="#section-05">5. 개인정보의 제 3자 제공</a>
          <a href="#section-06">6. 개인정보 위탁 업체 및 위탁업무 내용</a>
          <a href="#section-07">7. 정보주체의 권리 및 행사 방법</a>
          <a href="#section-08">8. 개인정보의 안전성 확보 조치</a>
          <a href="#section-09">9. 개인정보 관련 불만처리</a>
          <a href="#section-10">10. 개인정보보호 책임자 및 담당자 연락처</a>
          <a href="#section-11">11. 정책 변경에 따른 공지</a>
        </div>

        <div class="container">
          <div class="row privacy-detail mb-5">
            <section id="section-01" class="mt-5">
              <h5 class="mb-4"><strong>1. 개인정보의 수집 범위 및 방법</strong></h5>
              <p class="numText1">① “SK핀크스”는 적법하고 공정한 수단에 의하여 회원의 개인정보를 수집하고 있습니다.</p>
              <p class="numText1">② 수집되는 개인정보를 필수사항과 선택사항으로 구분하여 수집하고 있습니다.</p>
              <p class="numText2" style="margin-bottom: 8px">
                필수항목 : 회사명, 성명(영문, 한문포함), 생년월일, 주민등록번호, 우편물 수령지 주소, 핸드폰번호
              </p>
              <p class="numText2" style="margin-bottom: 8px">
                선택항목 : 직위, 전화번호(자택, 회사), 핸디캡, 보유 골프회원권, 이메일, 가족사항, 은행 계좌번호
              </p>
              <p class="numText1">
                ③ 수집하는 개인정보는 서비스 제공에 필요한 최소한의 정보만으로 한정되며, 회원의 기본적 인권을 침해할
                우려가 있는 민감한 개인정보(인종, 종교, 사상, 출신지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는
                수집하지 않습니다.
              </p>
              <p class="numText1">
                ④ “SK핀크스”는 다음과 같은 방법으로 개인정보를 수집합니다. 회원가입시 방문이나 우편 접수를 통해
                개인정보를 서면으로 수집하고 있습니다.
              </p>
            </section>
            <section id="section-02" class="mt-5">
              <h5 class="mb-4"><strong>2. 개인정보의 수집 및 이용목적</strong></h5>
              <p class="numText1">
                ① “SK핀크스”는 회원에게 클럽소식 등의 안내와 회원의 보다 편리하고 효율적인 호텔 및 골프장 이용을 위하여
                클럽소식지, 예약확인, 신규상품에 관한 유용한 정보를 제공하는데 이용합니다.
              </p>
              <p class="numText1">② “SK핀크스”는 다음과 같은 목적을 위해 개인정보를 수집하고 있습니다.</p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f1f0e8">
                    <td colspan="2">수집항목</td>
                    <td>목적</td>
                    <td>수집방법</td>
                  </tr>
                  <tr>
                    <td rowspan="3">필수항목</td>
                    <td>회사명, 성명(영문, 한문포함) 생년월일, 주민등록번호</td>
                    <td>
                      - 회원제 서비스 이용에 따른 본인 식별 절차에 이용<br />
                      - 골프장 예약 및 예약 확인<br />
                      - 회원권 및 회원카드 발행<br />
                      - 본인의사 확인, 불만처리 등 원할한 의사소통 경로 확보<br />
                      - 고객정보 분석 및 분석정보의 통계적 활용
                    </td>
                    <td>입회신청서</td>
                  </tr>
                  <tr>
                    <td>주소</td>
                    <td>
                      - 클럽 이용에 따른 소식지 전달<br />
                      - 회원 선물등 발송
                    </td>
                    <td>입회신청서</td>
                  </tr>
                  <tr>
                    <td>휴대폰번호</td>
                    <td>
                      - 고지사항 전달, 문의/상담, 불만처리 등 원활한 의사소통 경로의 확보<br />
                      - 골프/호텔 예약 및 예약 확인<br />
                      - 상품/서비스에 대한 마케팅, 홍보, 광고, 판매 고객 혜택 제공, 이벤트 정보 안내
                    </td>
                    <td>입회신청서</td>
                  </tr>
                  <tr>
                    <td rowspan="2">직장, 직위, 전화번호(회사, 자택) 주소(자택 직장), 이메일 가족사항</td>
                    <td>
                      - 고지사항 전달, 문의/상담, 불만처리 등 원활 한 의사소통 경로의 확보<br />
                      - 가족회원 가입<br />
                      - 상품/서비스에 대한 마케팅,홍보, 광고, 판매, 고객 혜액 제공, 이벤트 정보 안내
                    </td>
                    <td>입회신청서</td>
                  </tr>
                  <tr>
                    <td>핸디캡, 구력, 보유골프회원권</td>
                    <td>- 회원 골프 이력 관리</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      필수항목
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">회사명, 성명(영문, 한문포함) 생년월일, 주민등록번호</td>
                  </tr>
                  <tr>
                    <td>목적</td>
                    <td colspan="2">
                      - 회원제 서비스 이용에 따른 본인 식별 절차에 이용<br />
                      - 골프장 예약 및 예약 확인<br />
                      - 회원권 및 회원카드 발행<br />
                      - 본인의사 확인, 불만처리 등 원할한 의사소통 경로 확보<br />
                      - 고객정보 분석 및 분석정보의 통계적 활용
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      필수항목
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">주소</td>
                  </tr>
                  <tr>
                    <td>목적</td>
                    <td colspan="2">
                      - 클럽 이용에 따른 소식지 전달<br />
                      - 회원 선물등 발송
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      필수항목
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">휴대폰번호</td>
                  </tr>
                  <tr>
                    <td>목적</td>
                    <td colspan="2">
                      - 고지사항 전달, 문의/상담, 불만처리 등 원활한 의사소통 경로의 확보<br />
                      - 골프/호텔 예약 및 예약 확인<br />
                      - 상품/서비스에 대한 마케팅, 홍보, 광고, 판매 고객 혜택 제공, 이벤트 정보 안내
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="2" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      선택항목
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">직장, 직위, 전화번호(회사, 자택) 주소(자택 직장), 이메일 가족사항</td>
                  </tr>
                  <tr>
                    <td>목적</td>
                    <td colspan="2">
                      - 고지사항 전달, 문의/상담, 불만처리 등 원활 한 의사소통 경로의 확보<br />
                      - 가족회원 가입<br />
                      - 상품/서비스에 대한 마케팅,홍보, 광고, 판매, 고객 혜액 제공, 이벤트 정보 안내
                    </td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      필수항목
                    </td>
                  </tr>
                  <tr>
                    <td>수집항목</td>
                    <td colspan="2">핸디캡, 구력, 보유골프회원권</td>
                  </tr>
                  <tr>
                    <td>목적</td>
                    <td colspan="2">- 회원 골프 이력 관리</td>
                  </tr>
                  <tr>
                    <td>수집방법</td>
                    <td>입회신청서</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section id="section-03" class="mt-5">
              <h5 class="mb-4"><strong>3. 개인정보의 처리 및 취급 보유 기간</strong></h5>
              <p class="numText1">
                ① “SK핀크스”는 탈퇴 요청이나 멤버십 회원의 개인정보 삭제 요청 시에는 수집된 개인정보가 열람 또는 이용될
                수 없도록 즉시 처리합니다. 단 멤버십 회원의 경우 별도의 개인정보 삭제 요청이 없는 한, 그 보유기간을
                멤버십 가입 이력과 관리 등의 이유로 회원의 서비스 중단을 요청하실 때까지 이용 및 보관됩니다.
              </p>
              <p class="numText1">
                ② 하지만, 해지 시 상법 등 법령의 규정에 의하여 더 보존할 필요성이 있는 경우에는 법령에서 규정한 보존기간
                동안 거래내역과 최소한의 기본정보를 보유할 수 있으며 보유기간을 회원에게 미리 고지하거나 개별적으로
                회원의 동의를 받은 경우에는 약속한 보존기간 동안 개인정보를 보유합니다. 이 경우, “SK핀크스”는 보유하는
                정보를 그 보유 목적으로만 이용하며, 보존기간은 아래와 같습니다.
              </p>

              <p class="numText2">
                가. 계약 또는 청약철회 등에 관한 기록<br />보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br />보존기간
                : 5년
              </p>
              <p class="numText2">
                나. 대금결제 및 재화 등의 공급에 관한 기록<br />보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br />보존기간
                : 5년
              </p>
              <p class="numText2">
                다. 소비자의 불만 또는 분쟁처리에 관한 기록<br />보존이유 : 전자상거래 등에서의 소비자보호에 관한
                법률<br />보존기간 : 5년
              </p>
            </section>

            <section id="section-04" class="mt-5">
              <h5 class="mb-4"><strong>4. 개인정보의 파기절차 및 방법</strong></h5>
              <p class="numText1">
                “SK핀크스”는 적시파기 원칙에 의거하여 개인정보 수집 및 이용목적이 달성된 후에는 보유기간 및 이용기간에
                따라 해당 정보를 지체 없이 파기합니다. 구체적인 파기절차, 파기기한, 파기방법은 다음과 같습니다.
              </p>

              <p class="numText2">
                ① 파기절차 : 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한
                정보보호 사유에 따라 일정기간 저장된 후 파기되어 집니다. 동 개인정보는 법률에 의한 경우가 아니고서는
                보유 되어지는 이외의 다른 목적으로 이용되지 않습니다.
              </p>
              <p class="numText2">
                ② 파기기한 : 회원의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에
                파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을
                때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. 단,
                개인정보 수집 시 작성한 문서는 당사 사규에 따라 일괄 파기합니다.
              </p>
              <p class="numText2">
                ③ 파기방법<br />
                가. 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각<br />
                나. 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
              </p>
            </section>

            <section id="section-05" class="mt-5">
              <h5 class="mb-4"><strong>5. 개인정보의 제 3자 제공</strong></h5>
              <p class="numText1">
                “SK핀크스”는 회원의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
              </p>
              <p class="numText2">- 회원이 사전에 동의한 경우<br /></p>
              <p class="numText2">
                - 법령의 규정에 의거, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
              </p>
            </section>

            <section id="section-06" class="mt-5">
              <h5 class="mb-4"><strong>6. 개인정보 위탁 업체 및 위탁업무 내용</strong></h5>
              <p class="numText1">
                “SK핀크스”는 회원의 동의 없이 회원의 개인정보 취급을 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가
                생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 회원에게 통지하고 필요한 경우 사전 동의를 받도록
                하겠습니다.
              </p>
            </section>

            <section id="section-07" class="mt-5">
              <h5 class="mb-4">
                <strong>7. 정보주체의 권리 및 행사방법</strong>
              </h5>
              <p class="numText1">
                개인정보에 대한 열람, 정정, 삭제를 원하시는 경우 개인정보보호 책임자 및 담당부서에 유선 또는 이메일을
                통하여 본인 확인 후 변경 가능합니다.
              </p>
              <p class="numText2">
                가. 14세 미만 아동 및 법정대리인<br />- 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해
                만14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.<br />- 혹은
                개인정보보호 책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
              </p>
              <p class="numText2">
                나. 개인정보 열람 및 정정<br />회원의 개인정보 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
                전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한
                경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어 지도록 하겠습니다.
              </p>
              <p class="numText2">
                다. 개인정보 동의철회 및 권리 방법<br />“SK핀크스”는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
                삭제된 개인정보는 “SK핀크스가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의
                용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
              </p>
            </section>

            <section id="section-08" class="mt-5">
              <h5 class="mb-4">
                <strong>8. 개인정보의 안전성 확보 조치</strong>
              </h5>
              <p>
                “SK핀크스”는 개인정보보호법 제 29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적
                조치를 취하고 있습니다.
              </p>
              <p class="numText2">
                ① 개인정보 취급 인력의 최소화<br />개인정보보호를 위해 개인정보 취급자에 대한 권한을 최소화하고
                있습니다.
              </p>
              <p class="numText2">
                ② 개인정보 취급 인력에 대한 정기적 교육 시행<br />개인정보보호에 대한 인식 제고를 위해 정기적인 교육을
                시행하고 있습니다.
              </p>
              <p class="numText2">
                ③ 내부 점검 정기적 시행<br />개인정보의 취급 관련 안전성 확보를 위해 정기적으로 자체 점검을 실시하고
                있습니다.
              </p>
              <p class="numText2">
                ④ 내부 관리계획의 수립 및 시행<br />개인정보의 안전한 처리 및 관리를 위해 내부관리계획을 수립하여
                관리하고 있습니다.
              </p>
              <p class="numText2">
                ⑤ 개인정보의 암호화<br />회원의 개인정보와 비밀번호는 암호화되어 저장/관리되고 있으며 전송 시에도 별도의
                보안기능을 사용하여 안전하게 관리하고 있습니다.
              </p>
              <p class="numText2">
                ⑥ 해킹 등에 대한 기술적 대책<br />“SK핀크스”는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                훼손을 막기 위하 여 보안 프로그램을 설치하고 주기적인 갱신/점검을 하여 외부로부터 접근이 통제된 구 역에
                시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.
              </p>
              <p class="numText2">
                ⑦ 개인정보에 대한 접근 제한<br />개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경,
                말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단 시스템을 이용 하여
                외부로부터의 무단 접근을 통제하고 있습니다.
              </p>
              <p class="numText2">
                ⑧ 접속기록의 보관 및 위변조 방지<br />개인정보 처리/취급시스템에 접속한 기록을 최소 6개월 이상 보관,
                관리하고 있으며 접 속 기록이 위변조 및 도난 분실 되지 않도록 보안기능을 사용하고 있습니다.
              </p>
              <p class="numText2">
                ⑨ 문서보안을 위한 잠금장치 사용<br />개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한
                장소에 보관하고 있습니다.
              </p>
              <p class="numText2">
                ⑩ 비인가자에 대한 출입 통제<br />개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
                출입통제를 하고 있습니다.
              </p>
            </section>

            <section id="section-09" class="mt-5">
              <h5 class="mb-4">
                <strong>9. 개인정보관련 불만처리</strong>
              </h5>
              <p class="numText1">
                ① “SK핀크스”가 개인정보 취급방침을 지키지 않는다고 판단되시면, 아래 개인정보보호 책임자 또는
                개인정보보호 담당자에게 알려주십시오. 지적하신 문제를 즉시 확인하고 수정이 필요한 경우에는 최대한 빠른
                시간 내에 조치하도록 노력하겠습니다. “SK핀크스”는 회원들의 개인정보와 관련하여 회원 여러분들의 의견을
                수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다.
              </p>
              <p class="numText1">
                ② 또한 개인정보 침해에 대한 신고, 상담이 필요하신 경우에는 한국정보보호진흥원(KISA) 개인정보
                침해신고센터로 문의하시기 바랍니다. 회원이 개인정보침해를 통한 금전적, 정신적 피해를 입으신 경우에는
                개인정보 분쟁조정위원회에 피해구제를 신청하실 수 있습니다. 기타 개인정보에 관한 상담이 필요한 경우에는
                개인정보침해신고센터, 대검찰청 인터넷범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의 하실 수
                있습니다.
              </p>

              <p class="numText2">
                <strong>개인정보침해신고센터</strong><br />
                - 전화 : 1336<br />
                - URL : <a href="http://www.1336.or.kr">http://www.1336.or.kr</a><br /><br />
              </p>

              <p class="numText2">
                <strong>대검찰청 인터넷범죄수사센터</strong><br />
                - 전화 : 02-3480-3600<br />
                - URL : <a href="http://www.spo.go.kr">http://www.www.spo.go.kr</a><br /><br />
              </p>

              <p class="numText2">
                <strong>경찰청 사이버테러대응센터</strong><br />
                - 전화 : 02-392-0330<br />
                - URL : <a href="www.spo.go.kr">www.spo.go.kr</a><br /><br />
              </p>

              <p class="numText2">
                <strong>경찰청 사이버 범죄 수사대</strong><br />
                - URL : <a href="http://www.police.go.kr">http://www.police.go.kr</a>
              </p>
            </section>

            <section id="section-10" class="mt-5">
              <h5 class="mb-4">
                <strong>10. 개인정보보호 책임자 및 담당자의 연락처</strong>
              </h5>
              <p class="numText1">
                회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기위하여 “ SK핀크스”는 개인정보보호 책임자를
                두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보보호 책임자/담당자에게 연락 주시기
                바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해드리겠습니다.
              </p>
              <p class="numText2">
                <strong>개인정보보호 책임자</strong><br />
                - 성명 : 진명국<br />
                - 소속 : 기획마케팅팀<br />
                - 직책 : 팀장<br />
                - 이메일 : mgjin@sk.com<br />
                - 전화번호 : 064-793-5011<br /><br />
              </p>
              <p class="numText2">
                <strong>개인정보보호 담당자</strong><br />
                - 성명 : 고정환<br />
                - 소속 : 기획마케팅팀<br />
                - 직책 : 주임<br />
                - 이메일 : jhko@sk.com<br />
                - 전화번호 : 064-793-5086<br />
                (일반적인 문의는 SK핀크스 대표번호 (064-792-5200)로 문의하여 주시기 바랍니다.)
              </p>
            </section>

            <section id="section-11" class="mt-5">
              <h5 class="mb-4">
                <strong>11. 정책 변경에 따른 공지</strong>
              </h5>
              <p class="numText1">
                ① 본 개인정보취급방침은 “홈페이지” 첫 화면에 공개함으로써 회원이 언제나 용이하게 보실수 있도록 조치하고
                있습니다.
              </p>
              <p class="numText1">
                ② 법령 정책 또는 보안기술의 변경에 따라 내용이 추가 삭제 및 수정이 있을 시에는 변경되는
                개인정보취급방침을 시행하기 30일전에 “홈페이지”를 통해 변경 이유 및 내용 등을 공지하도록 하겠습니다.
              </p>

              <p class="numText1">
                ③ 본 개인정보취급방침의 내용은 수시로 변경될 수 있으므로 “홈페이지”를 방문하실 때마다, 이를 확인하시기
                바랍니다.
              </p>
              <p class="numText2" style="margin-bottom: 0px">- 개인정보취급방침 버전번호 : v1.0</p>

              <p class="numText2" style="margin-bottom: 0px">- 개인정보취급방침 공고일자 : 2013년 9월 01일</p>

              <p class="numText2" style="margin-bottom: 0px">- 개인정보취급방침 시행일자 : 2013년 9월 01일</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacyinfo',
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '개인정보 처리 방침'});
  },
};
</script>

<style scoped lang="scss">
p,
h1,
h2,
h3,
h4,
h5,
td {
  word-break: keep-all;
}
.bookmark-links a {
  float: left;
  display: block;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  font-size: 0.8rem;
  box-sizing: border-box;
}
td {
  vertical-align: middle;
}
a {
  color: #333;
}

.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
  font-weight: bold;
}
.numText2 {
  margin-left: 2.5rem;
  text-indent: -1.5rem;
}
.numText3 {
  margin-left: 2.5rem;
  text-indent: 0rem;
}
.innerText {
  margin: 1rem;
}
table_line_align {
  font-size: 0.88rem;
  margin-left: 1rem;
  text-indent: -1rem;
}

@media (min-width: 1200px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links a {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links a {
    width: 100%;
  }
}
</style>
